<nav class="navbar navbar-expand-lg navbar-light bg-light">
    <a class="navbar-brand" href="#">ThorDraco</a>
    <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarNav" aria-controls="navbarNav" aria-expanded="false" aria-label="Toggle navigation">
      <span class="navbar-toggler-icon"></span>
    </button>
    <div class="collapse navbar-collapse" id="navbarNav">
      <ul class="navbar-nav">
        <li class="nav-item active">
          <a class="nav-link" href="#">HOME</a>
        </li>
        <li class="nav-item">
          <a class="nav-link text-dark" href="https://www.twitch.tv/thordraco" target="_blank">TWITCH</a>

        </li>
        <li class="nav-item">
          <a class="nav-link text-dark" href="https://merch.streamelements.com/thordraco" target="_blank">MERCH</a>
        </li>
      </ul>
    </div>
  </nav>
